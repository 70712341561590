import { template as template_c9819bf399b24b2ba8e7d537a1e48f82 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_c9819bf399b24b2ba8e7d537a1e48f82(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_c9819bf399b24b2ba8e7d537a1e48f82(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_c9819bf399b24b2ba8e7d537a1e48f82(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
