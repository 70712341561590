import { template as template_31e7994d0cff4f5b9b2519d718a8fa0f } from "@ember/template-compiler";
const FKLabel = template_31e7994d0cff4f5b9b2519d718a8fa0f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
