import { template as template_b59581b023fe4f37bcbbf59b160d9189 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b59581b023fe4f37bcbbf59b160d9189(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
